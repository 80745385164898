<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">


          <h1>Направить массовое<br/>уведомление</h1>

          <div class="massNotify">
            <h4>Получатели</h4>

            <v-checkbox v-model="roleCznTerritorial" label="Территориальные центры занятости населения"></v-checkbox>
            <v-checkbox v-model="roleCznManager" label="Управляющие центры занятости населения"></v-checkbox>
            <v-checkbox v-model="roleVnii" label="Сотрудники ВНИИ труда"></v-checkbox>
            <v-checkbox v-model="roleAdmin" label="Администраторы"></v-checkbox>
            <v-checkbox v-model="roleRegionalAgency" label="Органы регионального управления"></v-checkbox>

            <v-text-field v-model="title" class="mt-48" placeholder="Тема уведомления"></v-text-field>

            <v-textarea v-model="text" placeholder="Сообщение"></v-textarea>


            <v-btn :disabled="loading" :loading="loading" color="primary" @click="submit">отправить</v-btn>
            <div><router-link to="/config/notifConfig">Настройка параметров оповещений</router-link></div>
          </div>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  props: {},
  components: {},
  data() {
    return {
      roleCznTerritorial: false,
      roleCznManager: false,
      roleVnii: false,
      roleAdmin: false,
      roleRegionalAgency: false,
      text: null,
      title: null,
      apiLoaded: true,
      errorText: null,
      loading: false
    };
  },
  methods: {
    async submit() {
      let t = this.validate()
      if (t !== true) {
        this.errorText = t
        return
      }
      this.errorText = null

      let roles = []
      if (this.roleCznTerritorial)
        roles.push('ROLE_CZN_TERRITORIAL')
      if (this.roleCznManager)
        roles.push('ROLE_CZN_MANAGER')
      if (this.roleVnii)
        roles.push('ROLE_VNII')
      if (this.roleAdmin)
        roles.push('ROLE_ADMIN')
      if (this.roleRegionalAgency)
        roles.push('ROLE_REGIONAL_AGENCY')

      let query = {roles: roles, text: this.text, title: this.title}
      // console.log(query)
      this.loading = true
      let req = await this.$postFormDataApi("/notifs/notifyUsers", query)
      if (req.ok) {
        this.$router.push('/config/messages')
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.loading = false
    },

    validate() {
      if (this.roleAdmin == null && this.roleVnii == null && this.roleCznTerritorial == null
          && this.roleCznTerritorial && this.roleRegionalAgency)
        return "Выберите хотя бы одну роль"
      if (this.title == null || this.title === '')
        return "Укажите тему уведомления"
      if (this.text == null || this.text === '')
        return "Укажите текст уведомления"
      return true
    }
  }

};
</script>
